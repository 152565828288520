<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування Географія" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Geography title *"
                  v-model="title"
                  :messages="['The recommended number of characters is 40']"
                  :error="!!errors.geography_title"
                  :error-messages="errors.geography_title"
                  @input="delete errors.geography_title"
                />
                <va-input
                  label="Geography content *"
                  v-model="content"
                  type="textarea"
                  :messages="['The recommended number of characters is 600']"
                  :error="!!errors.geography_content"
                  :error-messages="errors.geography_content"
                  @input="delete errors.geography_content"
                />
                <va-card class="mb-3" title="Geography image *">
                  <div v-if="imgExisting">
                    <a style="display: block;" target="_blank" :href="imgExisting"><img style="max-width: 400px;" :src="imgExisting"></a>
                    <div><va-button small color="danger" @click="imgExisting = deleteImage(imgId, 'geography_image') ? '' : imgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    @input="delete errors.geography_image"
                    v-model="img"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.geography_image" style="color: red;">{{ errors.geography_image[0] }}</div>
                  <va-button @click="uploadImage('geography_image', 'geography_image', img[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Geography button label *"
                  v-model="buttonLabel"
                  :messages="['The recommended number of characters is 15']"
                  :error="!!errors.geography_button_label"
                  :error-messages="errors.geography_button_label"
                  @input="delete errors.geography_button_label"
                />
                <va-button
                  @click="validate()"
                >Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      title: '',
      content: '',
      img: [],
      imgId: '',
      imgExisting: '',
      buttonLabel: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
    putData () {
      return {
        geography_title: this.title,
        geography_content: this.content,
        geography_button_label: this.buttonLabel,
      }
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (param, key, file) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append(param, file)
      formData.append('lang', this.locale)
      formData.append('key', key)
      formData.append('multiple', '0')
      formData.append('file_id', '0')
      axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/upload/9`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id, fieldKey) {
      return axios.post(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/9/delete-file/${id}`, {
        key: fieldKey,
      })
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/9`, this.putData)
        .then(response => {
          this.showToast('Successful update')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          this.showToast('Error')
        })
    },
    validate () {
      axios.put(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/validate/9`, this.putData, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => {
          this.errors = response.data
          if (!Object.keys(this.errors).length) {
            this.submit()
          }
        })
        .catch(error => {
          console.log(error)
          this.showToast('Validate Fetch Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_AUTOSTRADA_API_URL}/${this.locale}/admin/pages/9`)
        .then(response => {
          // console.log(response.data)
          this.title = response.data.geography_title
          this.content = response.data.geography_content
          this.buttonLabel = response.data.geography_button_label

          this.imgExisting = process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.geography_image
          this.imgId = response.data.geography_image_id
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
